import { Modal } from "../Modal";

const Sidebar = ({
  children,
  open,
  onClose,
  closable = true,
  showOverlay = false,
  overlayColor = "rgba(0, 0, 0, 0.5)",
}) => {
  return (
    <Modal
      open={open}
      showOverlay={showOverlay}
      overlayColor={overlayColor}
      closable={closable}
      isSidebar
      onClose={onClose}
    >
      {children}
    </Modal>
  );
};

export default Sidebar;
