export const TypographyVariants = {
  HEADING_1: "h1",
  HEADING_2: "h2",
  HEADING_3: "h3",
  HEADING_4: "h4",
  HEADING_5: "h5",
  HEADING_6: "h6",
  PARAGRAPH: "p",
  LIST_ITEM: "li",
  TABLE_HEADING: "th",
  TABLE_COLUMN: "td",
  DIV: "div",
  SPAN: "span",
  DEFAULT: "div"
};

export const TypographyFonts = {
  LIBRE_BODONI: "Libre Bodoni",
  AVENIR_NEXT: "Avenir Next",
  MERRIWEATHER: 'Merriweather'
};

export const ButtonType = {
  DEFAULT: 'default',
  PRIMARY: 'primary'
};

export const ImageLoading = {
  EAGER: 'eager',
  LAZY: 'lazy'
};

export const ImageTypes = {
  WEBP: 'webp',
  PNG: 'png',
  JPG: 'jpg',
  JPEG: 'jpeg',
  SVG: 'svg'
};

export const Position = {
  LEFT: 'left',
  RIGHT: 'right'
};

export const FlexAlignment = {
  FLEX_START: 'flex-start',
  FLEX_END: 'flex-end',
  CENTER: 'center',
  SPACE_BETWEEN: 'space-between',
  SPACE_AROUND: 'space-around',
  SPACE_EVENLY: 'space-evenly',
  STRETCH: 'stretch'
};

export const Orientation = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal'
};

export const HeaderMenuLinkHash = {
  MENU: 'menu',
  USE_CASES: 'use_cases'
};

export const capitalize = (word) => {
  if (!word || typeof word !== 'string' || word.length === 0) {
    return '';
  }
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const convertJsonStyleToString = (styleJson) => {
  if (!styleJson || typeof styleJson !== 'object' || Object.keys(styleJson).length === 0) {
    return '';
  }
  return Object.keys(styleJson).reduce((acc, key) => acc + key.split(/(?=[A-Z])/).join('-').toLowerCase() + ': ' + styleJson[key] + '; ', '').trim();
};

export const getMergedQueryParamsUrl = (url = '', browserWindow = {}) => {
  if (!url || !browserWindow || !browserWindow.location) {
    return '';
  }
  if (url.startsWith('#')) {
    return url;
  }
  let originalUrl = '';
  const baseUrl = `${browserWindow.location.protocol}//${browserWindow.location.host}`;
  try {
    originalUrl = url.startsWith('/') ? new URL(url, baseUrl) : new URL(url);
  } catch (error) {
    // Invalid URL or relative url, not sure what to do in these cases
    originalUrl = new URL(baseUrl);
  }
  let existingUrlParams = new URLSearchParams((browserWindow.location || { search: '' }).search);
  const newUrlParams = new URLSearchParams(originalUrl.search);
  // Note: spread the new params first and existing url params later here to give preference to the query param currently in the url
  // This will give preference to the existing query params when user lands on the page by clicking on marketing /link advertisement
  // If the current url and link url both have same query param present then we prefer to take value of the existing url query param
  const mergedUrlParams = new URLSearchParams({ ...Object.fromEntries(newUrlParams), ...Object.fromEntries(existingUrlParams) }).toString();
  originalUrl.search = '';
  originalUrl = originalUrl.toString();
  return `${originalUrl}${mergedUrlParams.length > 0 ? `?${mergedUrlParams}` : ''}`;
};

export const getUrlForAssets = (url) => {
  if (!url || url.length === 0) {
    return '';
  }
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL ? process.env.NEXT_PUBLIC_BASE_URL : 'http://localhost:3000';
  if (url.startsWith('/')) {
    return `${baseUrl}${url}`;
  } else if (url.startsWith('http://') || url.startsWith('https://')) {
    return url;
  }
  return `${baseUrl}/${url}`;
};
