import { useState, useEffect, createContext } from "react";
import { useRouter } from "next/router";

import "../styles/globals.scss";
import "../styles/common.scss";
import "../styles/util.scss";

import { data as en } from "../data/en";
import BookDemoForm from "../components/BookDemoForm";
import { useAtom } from "jotai";
import { geoAtom } from "../state";

export const App = ({ Component, pageProps }) => {
  const { locale } = useRouter();
  const [translations, setTranslations] = useState(en);
  const [geoIp, setGeoIp] = useAtom(geoAtom);

  const getTranslations = async ({ browserLocale, browserLanguage }) => {
    const language =
      browserLocale === browserLanguage ? browserLocale : browserLanguage;
    if (language !== "en") {
      switch (language) {
        case "af": {
          const { data: af } = await import("../data/af");
          setTranslations(af);
          break;
        }
        case "ar": {
          const { data: ar } = await import("../data/ar");
          setTranslations(ar);
          break;
        }
        case "de": {
          const { data: de } = await import("../data/de");
          setTranslations(de);
          break;
        }
        case "es": {
          const { data: es } = await import("../data/es");
          setTranslations(es);
          break;
        }
        case "fr": {
          const { data: fr } = await import("../data/fr");
          setTranslations(fr);
          break;
        }
        case "hi": {
          const { data: hi } = await import("../data/hi");
          setTranslations(hi);
          break;
        }
        case "it": {
          const { data: it } = await import("../data/it");
          setTranslations(it);
          break;
        }
        case "ja": {
          const { data: ja } = await import("../data/ja");
          setTranslations(ja);
          break;
        }
        case "ko": {
          const { data: ko } = await import("../data/ko");
          setTranslations(ko);
          break;
        }
        case "nl": {
          const { data: nl } = await import("../data/nl");
          setTranslations(nl);
          break;
        }
        case "pt": {
          const { data: pt } = await import("../data/pt");
          setTranslations(pt);
          break;
        }
        case "ru": {
          const { data: ru } = await import("../data/ru");
          setTranslations(ru);
          break;
        }
        case "ur": {
          const { data: ur } = await import("../data/ur");
          setTranslations(ur);
          break;
        }
        case "zh": {
          const { data: zh } = await import("../data/zh-CN");
          setTranslations(zh);
          break;
        }
        default: {
          break;
        }
      }
    }
  };

  const getGeoIp = async () => {
    try {
      const response = await fetch(
        "https://api.quicksell.co/v1/config/ip-location"
      );
      const { geo } = await response.json();
      setGeoIp(geo);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const browserLanguage = navigator ? navigator.language : "en";
    getTranslations({
      browserLocale: locale,
      browserLanguage: browserLanguage.split("-")[0],
    });
    getGeoIp();
  }, [locale]);

  return (
    <>
      <Component {...pageProps} {...translations} geoIp={geoIp} />
      <BookDemoForm />
    </>
  );
};

export default App;
