import { useEffect, useState } from "react";

import { ButtonType, TypographyVariants, getMergedQueryParamsUrl } from "../../Contracts";

import Typography from "../Typography";

import styles from "./Button.module.scss";

export const Button = (props) => {

  const [browserWindow, setBrowserWindow] = useState({});

  useEffect(() => {
    setBrowserWindow(window);
  }, []);

  const getButtonClick = () => {
    const { onClick } = props;
    if (typeof onClick === 'string' && browserWindow[onClick]) {
      return browserWindow[onClick];
    } else if (typeof onClick === 'function') {
      return onClick;
    }
    return undefined;
  };

  const getButtonHtmlType = (htmlType) => {
    if (!htmlType || htmlType.length === 0) {
      return 'button';
    }
    if (['button', 'reset', 'submit'].indexOf(htmlType) > -1) {
      return htmlType;
    }
    return 'button';
  };

  const getButtonType = (type) => {
    if (!type || type.length === 0) {
      return styles.DefaultButton;
    }
    switch (type) {
      case ButtonType.DEFAULT: {
        return styles.DefaultButton;
      }
      case ButtonType.PRIMARY: {
        return styles.PrimaryButton;
      }
      default: {
        return styles.DefaultButton;
      }
    }
  };

  const getButtonContent = () => {
    return (
      <Typography
        variant={TypographyVariants.SPAN}
        style={{ fontSize: '18px' }}
        tabletStyle={{ fontSize: '17px' }}
        mobileStyle={{ fontSize: '16px' }}
        className={`${styles.ButtonText} ${props.buttonTextClassName ? props.buttonTextClassName : ''}`}
      >
        {props.text || props.children}
      </Typography>
    );
  };

  if (!props.text && !props.children) {
    return null;
  }

  return (
    <button
      type={getButtonHtmlType(props.htmlType)}
      id={props.id}
      className={`${getButtonType(props.type)} ${styles.Button} ${props.className ? props.className : ''}`}
      style={props.style ? props.style : {}}
      disabled={props.disabled}
      onClick={getButtonClick()}
    >
      {props.linkUrl ? (
        <a href={getMergedQueryParamsUrl(props.linkUrl, browserWindow)} target={props.openInNewTab ? '_blank' : '_self'} rel={props.openInNewTab ? 'noopener noreferrer' : ''} className={`${styles.ButtonLink} ${props.buttonLinkClassName ? props.buttonLinkClassName : ''}`}>
          {getButtonContent()}
        </a>
      ) : getButtonContent()}
    </button>
  );

};

export default Button;
