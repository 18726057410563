import { FC, ReactNode, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

export const Portal = ({ children, childOfId = "portal" }) => {
  const ref = useRef(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    ref.current = document.getElementById(childOfId);
    setMounted(true);
  }, [childOfId]);

  return mounted ? createPortal(children, ref.current) : null;
};
