import { getCountryFromSymbol } from "../components/Common/PhoneDropdown/countries";

export const getTruncatedPhoneNumber = ({ phone, countryCode, phoneUtil }) => {
  try {
    const parsedNumber = phoneUtil?.parseAndKeepRawInput(phone, countryCode);
    const nationalNumber = parsedNumber?.getNationalNumber();
    return JSON.stringify(nationalNumber);
  } catch (err) {
    return phone;
  }
};

export const parsePhoneNumber = ({ phone, phoneUtil }) => {
  const parsedNumber = phoneUtil?.parseAndKeepRawInput(phone, "IN");
  return parsedNumber;
};

export const validatePhoneNumber = ({
  phone,
  countryCode,
  setCountry,
  phoneUtil,
}) => {
  try {
    const parsedNumber = phoneUtil?.parseAndKeepRawInput(phone, countryCode);
    const countrySymbol = parsedNumber?.getCountryCode();
    const isValidNumber = phoneUtil?.isValidNumber(parsedNumber);

    const country = getCountryFromSymbol(`+${countrySymbol}`);
    if (country && setCountry) setCountry(country);
    return isValidNumber;
  } catch (err) {
    return !!phone && phone.length > 0;
  }
};
