import { atom } from "jotai";
import { getCountryFromCountryCode } from "../components/Common/PhoneDropdown/countries";

export const geoAtom = atom();

export const countryStoreAtom = atom(undefined);

export const countryAtom = atom(
  (get) => {
    const geo = get(geoAtom);
    const storedCountry = get(countryStoreAtom);

    if (storedCountry) {
      return storedCountry;
    } else if (geo?.data) {
      const {
        geo: { countryCode },
      } = geo.data;

      const country = getCountryFromCountryCode(countryCode);
      return country;
    }

    return getCountryFromCountryCode("IN");
  },
  (get, set, country) => {
    set(countryStoreAtom, country);
  }
);

export const phoneUtilAtom = atom(undefined);

export const bookDemoFormOpen = atom(false);
