import { useSetAtom } from "jotai";
import { leadFormAtom } from "./state";
import styles from "./styles.module.scss";
import CloseIcon from "../Common/Icons/Close";
import OkIcon from "../Common/Icons/Ok";
import { useEffect } from "react";

const ThankYouPage = ({ close }) => {
  const dispatch = useSetAtom(leadFormAtom);

  useEffect(() => {
    const timer = setTimeout(() => {
      close();
      dispatch({ type: "reset" });
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [close, dispatch]);

  return (
    <div className={styles.ThankYouContainer}>
      <CloseIcon size={28} onClick={close} className={styles.CloseIcon} />
      <OkIcon className={styles.OkIcon} />
      <p className={styles.ThankYouText}>Thank you! We will be in touch soon</p>
    </div>
  );
};

export default ThankYouPage;
