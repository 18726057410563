const countries = [
  { countryCode: "AC", label: "Ascension Island", symbol: "+247" },
  { countryCode: "AD", label: "Andorra", symbol: "+376" },
  { countryCode: "AE", label: "United Arab Emirates", symbol: "+971" },
  { countryCode: "AF", label: "Afghanistan", symbol: "+93" },
  { countryCode: "AG", label: "Antigua And Barbuda", symbol: "+1 268" },
  { countryCode: "AI", label: "Anguilla", symbol: "+1 264" },
  { countryCode: "AL", label: "Albania", symbol: "+355" },
  { countryCode: "AM", label: "Armenia", symbol: "+374" },
  { countryCode: "AO", label: "Angola", symbol: "+244" },
  { countryCode: "AQ", label: "Antarctica", symbol: "+672" },
  { countryCode: "AR", label: "Argentina", symbol: "+54" },
  { countryCode: "AS", label: "American Samoa", symbol: "+1 684" },
  { countryCode: "AT", label: "Austria", symbol: "+43" },
  { countryCode: "AU", label: "Australia", symbol: "+61" },
  { countryCode: "AW", label: "Aruba", symbol: "+297" },
  { countryCode: "AX", label: "Åland Islands", symbol: "+358" },
  { countryCode: "AZ", label: "Azerbaijan", symbol: "+994" },
  { countryCode: "BA", label: "Bosnia & Herzegovina", symbol: "+387" },
  { countryCode: "BB", label: "Barbados", symbol: "+1 246" },
  { countryCode: "BD", label: "Bangladesh", symbol: "+880" },
  { countryCode: "BE", label: "Belgium", symbol: "+32" },
  { countryCode: "BF", label: "Burkina Faso", symbol: "+226" },
  { countryCode: "BG", label: "Bulgaria", symbol: "+359" },
  { countryCode: "BH", label: "Bahrain", symbol: "+973" },
  { countryCode: "BI", label: "Burundi", symbol: "+257" },
  { countryCode: "BJ", label: "Benin", symbol: "+229" },
  { countryCode: "BL", label: "Saint Barthélemy", symbol: "+590" },
  { countryCode: "BM", label: "Bermuda", symbol: "+1 441" },
  { countryCode: "BN", label: "Brunei Darussalam", symbol: "+673" },
  {
    countryCode: "BO",
    label: "Bolivia, Plurinational State Of",
    symbol: "+591",
  },
  {
    countryCode: "BQ",
    label: "Bonaire, Saint Eustatius And Saba",
    symbol: "+599",
  },
  { countryCode: "BR", label: "Brazil", symbol: "+55" },
  { countryCode: "BS", label: "Bahamas", symbol: "+1 242" },
  { countryCode: "BT", label: "Bhutan", symbol: "+975" },
  { countryCode: "BW", label: "Botswana", symbol: "+267" },
  { countryCode: "BY", label: "Belarus", symbol: "+375" },
  { countryCode: "BZ", label: "Belize", symbol: "+501" },
  { countryCode: "CA", label: "Canada", symbol: "+1" },
  { countryCode: "CC", label: "Cocos (Keeling) Islands", symbol: "+61" },
  {
    countryCode: "CD",
    label: "Democratic Republic Of Congo",
    symbol: "+243",
  },
  { countryCode: "CF", label: "Central African Republic", symbol: "+236" },
  { countryCode: "CG", label: "Republic Of Congo", symbol: "+242" },
  { countryCode: "CH", label: "Switzerland", symbol: "+41" },
  { countryCode: "CI", label: "Côte d'Ivoire", symbol: "+225" },
  { countryCode: "CK", label: "Cook Islands", symbol: "+682" },
  { countryCode: "CL", label: "Chile", symbol: "+56" },
  { countryCode: "CM", label: "Cameroon", symbol: "+237" },
  { countryCode: "CN", label: "China", symbol: "+86" },
  { countryCode: "CO", label: "Colombia", symbol: "+57" },
  { countryCode: "CR", label: "Costa Rica", symbol: "+506" },
  { countryCode: "CU", label: "Cuba", symbol: "+53" },
  { countryCode: "CV", label: "Cabo Verde", symbol: "+238" },
  { countryCode: "CW", label: "Curacao", symbol: "+599" },
  { countryCode: "CX", label: "Christmas Island", symbol: "+61" },
  { countryCode: "CY", label: "Cyprus", symbol: "+357" },
  { countryCode: "CZ", label: "Czech Republic", symbol: "+420" },
  { countryCode: "DE", label: "Germany", symbol: "+49" },
  { countryCode: "DJ", label: "Djibouti", symbol: "+253" },
  { countryCode: "DK", label: "Denmark", symbol: "+45" },
  { countryCode: "DM", label: "Dominica", symbol: "+1 767" },
  { countryCode: "DO", label: "Dominican Republic", symbol: "+1 809" },
  { countryCode: "DO", label: "Dominican Republic", symbol: "+1 829" },
  { countryCode: "DO", label: "Dominican Republic", symbol: "+1 849" },
  { countryCode: "DZ", label: "Algeria", symbol: "+213" },
  { countryCode: "EC", label: "Ecuador", symbol: "+593" },
  { countryCode: "EE", label: "Estonia", symbol: "+372" },
  { countryCode: "EG", label: "Egypt", symbol: "+20" },
  { countryCode: "EH", label: "Western Sahara", symbol: "+212" },
  { countryCode: "ER", label: "Eritrea", symbol: "+291" },
  { countryCode: "ES", label: "Spain", symbol: "+34" },
  { countryCode: "ET", label: "Ethiopia", symbol: "+251" },
  { countryCode: "EU", label: "European Union", symbol: "+388" },
  { countryCode: "FI", label: "Finland", symbol: "+358" },
  { countryCode: "FJ", label: "Fiji", symbol: "+679" },
  { countryCode: "FK", label: "Falkland Islands", symbol: "+500" },
  {
    countryCode: "FM",
    label: "Micronesia, Federated States Of",
    symbol: "+691",
  },
  { countryCode: "FO", label: "Faroe Islands", symbol: "+298" },
  { countryCode: "FR", label: "France", symbol: "+33" },
  { countryCode: "GA", label: "Gabon", symbol: "+241" },
  { countryCode: "GB", label: "United Kingdom", symbol: "+44" },
  { countryCode: "GD", label: "Grenada", symbol: "+473" },
  { countryCode: "GE", label: "Georgia", symbol: "+995" },
  { countryCode: "GF", label: "French Guiana", symbol: "+594" },
  { countryCode: "GG", label: "Guernsey", symbol: "+44" },
  { countryCode: "GH", label: "Ghana", symbol: "+233" },
  { countryCode: "GI", label: "Gibraltar", symbol: "+350" },
  { countryCode: "GL", label: "Greenland", symbol: "+299" },
  { countryCode: "GM", label: "Gambia", symbol: "+220" },
  { countryCode: "GN", label: "Guinea", symbol: "+224" },
  { countryCode: "GP", label: "Guadeloupe", symbol: "+590" },
  { countryCode: "GQ", label: "Equatorial Guinea", symbol: "+240" },
  { countryCode: "GR", label: "Greece", symbol: "+30" },
  { countryCode: "GT", label: "Guatemala", symbol: "+502" },
  { countryCode: "GU", label: "Guam", symbol: "+1 671" },
  { countryCode: "GW", label: "Guinea-bissau", symbol: "+245" },
  { countryCode: "GY", label: "Guyana", symbol: "+592" },
  { countryCode: "HK", label: "Hong Kong", symbol: "+852" },
  { countryCode: "HN", label: "Honduras", symbol: "+504" },
  { countryCode: "HR", label: "Croatia", symbol: "+385" },
  { countryCode: "HT", label: "Haiti", symbol: "+509" },
  { countryCode: "HU", label: "Hungary", symbol: "+36" },
  { countryCode: "ID", label: "Indonesia", symbol: "+62" },
  { countryCode: "IE", label: "Ireland", symbol: "+353" },
  { countryCode: "IL", label: "Israel", symbol: "+972" },
  { countryCode: "IM", label: "Isle Of Man", symbol: "+44" },
  { countryCode: "IN", label: "India", symbol: "+91" },
  {
    countryCode: "IO",
    label: "British Indian Ocean Territory",
    symbol: "+246",
  },
  { countryCode: "IQ", label: "Iraq", symbol: "+964" },
  { countryCode: "IR", label: "Iran, Islamic Republic Of", symbol: "+98" },
  { countryCode: "IS", label: "Iceland", symbol: "+354" },
  { countryCode: "IT", label: "Italy", symbol: "+39" },
  { countryCode: "JE", label: "Jersey", symbol: "+44" },
  { countryCode: "JM", label: "Jamaica", symbol: "+1 876" },
  { countryCode: "JO", label: "Jordan", symbol: "+962" },
  { countryCode: "JP", label: "Japan", symbol: "+81" },
  { countryCode: "KE", label: "Kenya", symbol: "+254" },
  { countryCode: "KG", label: "Kyrgyzstan", symbol: "+996" },
  { countryCode: "KH", label: "Cambodia", symbol: "+855" },
  { countryCode: "KI", label: "Kiribati", symbol: "+686" },
  { countryCode: "KM", label: "Comoros", symbol: "+269" },
  { countryCode: "KN", label: "Saint Kitts And Nevis", symbol: "+1 869" },
  {
    countryCode: "KP",
    label: "Korea, Democratic People's Republic Of",
    symbol: "+850",
  },
  { countryCode: "KR", label: "Korea, Republic Of", symbol: "+82" },
  { countryCode: "KW", label: "Kuwait", symbol: "+965" },
  { countryCode: "KY", label: "Cayman Islands", symbol: "+1 345" },
  { countryCode: "KZ", label: "Kazakhstan", symbol: "+7" },
  { countryCode: "KZ", label: "Kazakhstan", symbol: "+7 6" },
  { countryCode: "KZ", label: "Kazakhstan", symbol: "+7 7" },
  {
    countryCode: "LA",
    label: "Lao People's Democratic Republic",
    symbol: "+856",
  },
  { countryCode: "LB", label: "Lebanon", symbol: "+961" },
  { countryCode: "LC", label: "Saint Lucia", symbol: "+1 758" },
  { countryCode: "LI", label: "Liechtenstein", symbol: "+423" },
  { countryCode: "LK", label: "Sri Lanka", symbol: "+94" },
  { countryCode: "LR", label: "Liberia", symbol: "+231" },
  { countryCode: "LS", label: "Lesotho", symbol: "+266" },
  { countryCode: "LT", label: "Lithuania", symbol: "+370" },
  { countryCode: "LU", label: "Luxembourg", symbol: "+352" },
  { countryCode: "LV", label: "Latvia", symbol: "+371" },
  { countryCode: "LY", label: "Libya", symbol: "+218" },
  { countryCode: "MA", label: "Morocco", symbol: "+212" },
  { countryCode: "MC", label: "Monaco", symbol: "+377" },
  { countryCode: "MD", label: "Moldova", symbol: "+373" },
  { countryCode: "ME", label: "Montenegro", symbol: "+382" },
  { countryCode: "MF", label: "Saint Martin", symbol: "+590" },
  { countryCode: "MG", label: "Madagascar", symbol: "+261" },
  { countryCode: "MH", label: "Marshall Islands", symbol: "+692" },
  {
    countryCode: "MK",
    label: "Macedonia, The Former Yugoslav Republic Of",
    symbol: "+389",
  },
  { countryCode: "ML", label: "Mali", symbol: "+223" },
  { countryCode: "MM", label: "Myanmar", symbol: "+95" },
  { countryCode: "MN", label: "Mongolia", symbol: "+976" },
  { countryCode: "MO", label: "Macao", symbol: "+853" },
  {
    countryCode: "MP",
    label: "Northern Mariana Islands",
    symbol: "+1 670",
  },
  { countryCode: "MQ", label: "Martinique", symbol: "+596" },
  { countryCode: "MR", label: "Mauritania", symbol: "+222" },
  { countryCode: "MS", label: "Montserrat", symbol: "+1 664" },
  { countryCode: "MT", label: "Malta", symbol: "+356" },
  { countryCode: "MU", label: "Mauritius", symbol: "+230" },
  { countryCode: "MV", label: "Maldives", symbol: "+960" },
  { countryCode: "MW", label: "Malawi", symbol: "+265" },
  { countryCode: "MX", label: "Mexico", symbol: "+52" },
  { countryCode: "MY", label: "Malaysia", symbol: "+60" },
  { countryCode: "MZ", label: "Mozambique", symbol: "+258" },
  { countryCode: "NA", label: "Namibia", symbol: "+264" },
  { countryCode: "NC", label: "New Caledonia", symbol: "+687" },
  { countryCode: "NE", label: "Niger", symbol: "+227" },
  { countryCode: "NF", label: "Norfolk Island", symbol: "+672" },
  { countryCode: "NG", label: "Nigeria", symbol: "+234" },
  { countryCode: "NI", label: "Nicaragua", symbol: "+505" },
  { countryCode: "NL", label: "Netherlands", symbol: "+31" },
  { countryCode: "NO", label: "Norway", symbol: "+47" },
  { countryCode: "NP", label: "Nepal", symbol: "+977" },
  { countryCode: "NR", label: "Nauru", symbol: "+674" },
  { countryCode: "NU", label: "Niue", symbol: "+683" },
  { countryCode: "NZ", label: "New Zealand", symbol: "+64" },
  { countryCode: "OM", label: "Oman", symbol: "+968" },
  { countryCode: "PA", label: "Panama", symbol: "+507" },
  { countryCode: "PE", label: "Peru", symbol: "+51" },
  { countryCode: "PF", label: "French Polynesia", symbol: "+689" },
  { countryCode: "PG", label: "Papua New Guinea", symbol: "+675" },
  { countryCode: "PH", label: "Philippines", symbol: "+63" },
  { countryCode: "PK", label: "Pakistan", symbol: "+92" },
  { countryCode: "PL", label: "Poland", symbol: "+48" },
  { countryCode: "PM", label: "Saint Pierre And Miquelon", symbol: "+508" },
  { countryCode: "PN", label: "Pitcairn", symbol: "+872" },
  { countryCode: "PR", label: "Puerto Rico", symbol: "+1 787" },
  { countryCode: "PR", label: "Puerto Rico", symbol: "+1 939" },
  {
    countryCode: "PS",
    label: "Palestinian Territory, Occupied",
    symbol: "+970",
  },
  { countryCode: "PT", label: "Portugal", symbol: "+351" },
  { countryCode: "PW", label: "Palau", symbol: "+680" },
  { countryCode: "PY", label: "Paraguay", symbol: "+595" },
  { countryCode: "QA", label: "Qatar", symbol: "+974" },
  { countryCode: "RE", label: "Reunion", symbol: "+262" },
  { countryCode: "RO", label: "Romania", symbol: "+40" },
  { countryCode: "RS", label: "Serbia", symbol: "+381" },
  { countryCode: "RU", label: "Russian Federation", symbol: "+7" },
  { countryCode: "RU", label: "Russian Federation", symbol: "+7 3" },
  { countryCode: "RU", label: "Russian Federation", symbol: "+7 4" },
  { countryCode: "RU", label: "Russian Federation", symbol: "+7 8" },
  { countryCode: "RW", label: "Rwanda", symbol: "+250" },
  { countryCode: "SA", label: "Saudi Arabia", symbol: "+966" },
  { countryCode: "SB", label: "Solomon Islands", symbol: "+677" },
  { countryCode: "SC", label: "Seychelles", symbol: "+248" },
  { countryCode: "SD", label: "Sudan", symbol: "+249" },
  { countryCode: "SE", label: "Sweden", symbol: "+46" },
  { countryCode: "SG", label: "Singapore", symbol: "+65" },
  {
    countryCode: "SH",
    label: "Saint Helena, Ascension And Tristan Da Cunha",
    symbol: "+290",
  },
  { countryCode: "SI", label: "Slovenia", symbol: "+386" },
  { countryCode: "SJ", label: "Svalbard And Jan Mayen", symbol: "+47" },
  { countryCode: "SK", label: "Slovakia", symbol: "+421" },
  { countryCode: "SL", label: "Sierra Leone", symbol: "+232" },
  { countryCode: "SM", label: "San Marino", symbol: "+378" },
  { countryCode: "SN", label: "Senegal", symbol: "+221" },
  { countryCode: "SO", label: "Somalia", symbol: "+252" },
  { countryCode: "SR", label: "Suriname", symbol: "+597" },
  { countryCode: "SS", label: "South Sudan", symbol: "+211" },
  { countryCode: "ST", label: "Sao Tome and Principe", symbol: "+239" },
  { countryCode: "SV", label: "El Salvador", symbol: "+503" },
  { countryCode: "SX", label: "Sint Maarten", symbol: "+1 721" },
  { countryCode: "SY", label: "Syrian Arab Republic", symbol: "+963" },
  { countryCode: "SZ", label: "Swaziland", symbol: "+268" },
  { countryCode: "TA", label: "Tristan de Cunha", symbol: "+290" },
  {
    countryCode: "TC",
    label: "Turks And Caicos Islands",
    symbol: "+1 649",
  },
  { countryCode: "TD", label: "Chad", symbol: "+235" },
  { countryCode: "TG", label: "Togo", symbol: "+228" },
  { countryCode: "TH", label: "Thailand", symbol: "+66" },
  { countryCode: "TJ", label: "Tajikistan", symbol: "+992" },
  { countryCode: "TK", label: "Tokelau", symbol: "+690" },
  {
    countryCode: "TL",
    label: "Timor-Leste, Democratic Republic of",
    symbol: "+670",
  },
  { countryCode: "TM", label: "Turkmenistan", symbol: "+993" },
  { countryCode: "TN", label: "Tunisia", symbol: "+216" },
  { countryCode: "TO", label: "Tonga", symbol: "+676" },
  { countryCode: "TR", label: "Turkey", symbol: "+90" },
  { countryCode: "TT", label: "Trinidad And Tobago", symbol: "+1 868" },
  { countryCode: "TV", label: "Tuvalu", symbol: "+688" },
  { countryCode: "TW", label: "Taiwan", symbol: "+886" },
  {
    countryCode: "TZ",
    label: "Tanzania, United Republic Of",
    symbol: "+255",
  },
  { countryCode: "UA", label: "Ukraine", symbol: "+380" },
  { countryCode: "UG", label: "Uganda", symbol: "+256" },
  {
    countryCode: "UM",
    label: "United States Minor Outlying Islands",
    symbol: "+1",
  },
  { countryCode: "US", label: "United States", symbol: "+1" },
  { countryCode: "UY", label: "Uruguay", symbol: "+598" },
  { countryCode: "UZ", label: "Uzbekistan", symbol: "+998" },
  { countryCode: "VA", label: "Vatican City State", symbol: "+379" },
  { countryCode: "VA", label: "Vatican City State", symbol: "+39" },
  {
    countryCode: "VC",
    label: "Saint Vincent And The Grenadines",
    symbol: "+1 784",
  },
  {
    countryCode: "VE",
    label: "Venezuela, Bolivarian Republic Of",
    symbol: "+58",
  },
  {
    countryCode: "VG",
    label: "Virgin Islands (British)",
    symbol: "+1 284",
  },
  { countryCode: "VI", label: "Virgin Islands (US)", symbol: "+1 340" },
  { countryCode: "VN", label: "Viet Nam", symbol: "+84" },
  { countryCode: "VU", label: "Vanuatu", symbol: "+678" },
  { countryCode: "WF", label: "Wallis And Futuna", symbol: "+681" },
  { countryCode: "WS", label: "Samoa", symbol: "+685" },
  { countryCode: "XK", label: "Kosovo", symbol: "+383" },
  { countryCode: "YE", label: "Yemen", symbol: "+967" },
  { countryCode: "YT", label: "Mayotte", symbol: "+262" },
  { countryCode: "ZA", label: "South Africa", symbol: "+27" },
  { countryCode: "ZM", label: "Zambia", symbol: "+260" },
  { countryCode: "ZW", label: "Zimbabwe", symbol: "+263" },
];
countries.sort((c1, c2) => c1.label.localeCompare(c2.label));

export const getCountries = () => countries;

export const getFilteredCountries = (search) => {
  if (!search) {
    return countries;
  }

  return countries.filter(
    (c) =>
      c.label.toLowerCase().includes(search.toLowerCase()) ||
      c.symbol.includes(search)
  );
};

export const getCountryFromCountryCode = (countryCode) => {
  const countries = getCountries();

  return countries.find((country) => country.countryCode === countryCode);
};

export const getCountryFromSymbol = (symbol) => {
  const countries = getCountries();

  return countries.find((country) => country.symbol === symbol);
};

export const getFlagUrl = (countryCode) =>
  `https://catalogue-cdn.quicksell.co/s/assets/flags/${countryCode.toLowerCase()}.png`;

export const INDIA = { countryCode: "IN", label: "India", symbol: "+91" };
