import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import PhoneDropdown from "../PhoneDropdown";
import { PhoneNumberUtil } from "google-libphonenumber";
import clsx from "clsx";
import styles from "./PhoneInput.module.scss";
import { useAtom } from "jotai";
import { validatePhoneNumber } from "../../../utils/validate-phone";
import { countryAtom } from "../../../state";

const PhoneInput = ({
  disabled,
  setState,
  className,
  value,
  onInputKeyDown,
  autoFocus,
  setInvalid,
  setErrored,
  placeholder = "Enter your Whatsapp number",
  errored,
  customTrigger,
}) => {
  const dropdownRef = useRef(null);
  const phoneUtil = useMemo(() => PhoneNumberUtil.getInstance(), []);
  const [country, setCountry] = useAtom(countryAtom);

  const handleSetCountry = useCallback(
    (country) => {
      setCountry(country);
    },
    [setCountry]
  );

  const handleOnChange = (e) => {
    const phone = e.target.value;
    setState("phone", phone);
    setErrored?.(false);
    if (phoneUtil) {
      const isValid = validatePhoneNumber({
        phone,
        countryCode: country.countryCode,
        setCountry: handleSetCountry,
        phoneUtil,
      });
      setInvalid?.(!isValid);
    }
  };

  useEffect(() => {
    setState("country", country);
  }, [country, setState]);

  const resizeDropdown = useCallback((node) => {
    if (dropdownRef.current) {
      dropdownRef.current.resize(node);
    }
  }, []);

  return (
    <div
      ref={resizeDropdown}
      className={clsx(
        className,
        styles.PhoneInput,
        disabled ? styles.Disabled : "",
        errored ? styles.Errored : ""
      )}
    >
      <PhoneDropdown
        ref={dropdownRef}
        setErrored={setErrored}
        setCountry={handleSetCountry}
        country={country}
      />
      <input
        type="tel"
        autoFocus={autoFocus}
        onKeyDown={onInputKeyDown}
        value={value.number}
        onChange={handleOnChange}
        placeholder={placeholder}
        className={clsx(styles.Input, disabled && styles.InputDisabled)}
      />
    </div>
  );
};

export default PhoneInput;
