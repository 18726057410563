const OkIcon = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="73" height="73" {...props}>
      <defs>
        <linearGradient
          x1="14.645%"
          y1="14.645%"
          x2="85.355%"
          y2="85.355%"
          id="a"
        >
          <stop stopColor="#21AD64" offset="0%" />
          <stop stopColor="#088242" offset="100%" />
        </linearGradient>
      </defs>
      <g fillRule="nonzero" fill="none">
        <path
          d="M73 36.5C73 56.657 56.657 73 36.5 73S0 56.657 0 36.5 16.343 0 36.5 0 73 16.343 73 36.5z"
          fill="url(#a)"
        />
        <path
          d="M51.117 22.082 32.4 40.864l-9.517-9.55a3.67 3.67 0 0 0-5.203 0l-2.602 2.61a3.701 3.701 0 0 0 0 5.223l14.72 14.771a3.67 3.67 0 0 0 5.204 0l23.92-24.004a3.701 3.701 0 0 0 0-5.222l-2.602-2.61a3.67 3.67 0 0 0-5.203 0z"
          fill="#000"
          opacity=".05"
        />
        <path
          d="M30.472 53.194 15.806 38.492a2.76 2.76 0 0 1 0-3.897l2.592-2.599a2.744 2.744 0 0 1 3.889 0l10.13 10.153 19.296-19.341a2.744 2.744 0 0 1 3.889 0l2.592 2.598a2.76 2.76 0 0 1 0 3.898l-23.833 23.89a2.747 2.747 0 0 1-3.889 0z"
          fill="#000"
          opacity=".07"
        />
        <path
          d="M31.144 52.464 16.535 37.843a1.827 1.827 0 0 1 0-2.584l2.583-2.585a1.823 1.823 0 0 1 2.582 0L32.435 43.42 52.3 23.536a1.823 1.823 0 0 1 2.582 0l2.583 2.584a1.827 1.827 0 0 1 0 2.585l-23.74 23.759a1.823 1.823 0 0 1-2.581 0z"
          fill="#FFF"
        />
      </g>
    </svg>
  );
};

export default OkIcon;
