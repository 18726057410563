import { atomWithReducer } from "jotai/utils";
import { atom } from "jotai";

export const leadResponseAtom = atom(undefined);

const leadFormInitialState = {
  businessName: {
    value: "",
    error: "",
  },
  phone: {
    value: "",
    error: "",
  },
  natureOfBusiness: {
    value: "",
    error: "",
  },
  numberOfProducts: {
    value: "",
    error: "",
  },
  numberOfCustomersPerDayProductsSharedWith: {
    value: "",
    error: "",
  },
  country: {
    value: "",
    error: "",
  },
};

const leadFormReducer = (state, action) => {
  if (action.type === "errors") {
    const { errors } = action;

    Object.keys(errors).forEach((key) => {
      const error = errors[key];
      if (error) {
        state[key] = {
          ...state[key],
          error,
        };
      }
    });

    return { ...state };
  }

  if (action.type === "reset") {
    return leadFormInitialState;
  }

  const { error, value, type } = action;
  return {
    ...state,
    [type]: {
      ...state[type],
      value,
      error,
    },
  };
};

export const leadFormAtom = atomWithReducer(
  leadFormInitialState,
  leadFormReducer
);
