import React from "react";
import styles from "./LabelledInput.module.scss";
import Typography from "../Typography";

const LabelledInput = ({
  label,
  error,
  className,
  hideEmptyErrors,
  pre,
  ...props
}) => {
  return (
    <div className={`${styles.LabelledInput} ${className}`}>
      <Typography variant="p" className={styles.Label}>
        {label}
      </Typography>
      <div
        className={`${styles.InputContainer} ${error ? "error" : ""} ${
          props.disabled ? "disabled" : ""
        }`}
      >
        {pre && <span className={styles.Pre}>{pre}</span>}
        <input className={styles.Input} {...props} />
      </div>
      {(!hideEmptyErrors || !!error) && (
        <p className={`${styles.ErrorText} ${error ? styles.Visible : ""}`}>
          {error}
        </p>
      )}
    </div>
  );
};

export default LabelledInput;
