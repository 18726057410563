import React, { useCallback, useEffect } from "react";
import { useAtom, useSetAtom } from "jotai";
import Sidebar from "../Common/Sidebar";
import BookDemoFormMain from "./form";
import { leadFormAtom } from "./state";
import CloseIcon from "../Common/Icons/Close";
import { bookDemoFormOpen } from "../../state";

const BookDemoForm = () => {
  const [open, setOpen] = useAtom(bookDemoFormOpen);
  const dispatch = useSetAtom(leadFormAtom);

  const onSidebarClose = useCallback(() => {
    setOpen(false);
    dispatch({ type: "reset" });
  }, [setOpen]);

  useEffect(() => {
    document.addEventListener("openBookDemo", () => {
      setOpen(true);
    });
  }, []);

  return (
    <Sidebar
      open={open}
      onClose={onSidebarClose}
      showOverlay={true}
      overlayColor="#000000bf"
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          position: "absolute",
          right: 0,
        }}
      >
        <span
          style={{
            padding: 12,
            cursor: "pointer",
          }}
          onClick={onSidebarClose}
        >
          <CloseIcon size={32} />
        </span>
      </div>
      <BookDemoFormMain />
    </Sidebar>
  );
};

export default BookDemoForm;
