let cachedPromise;

const loadPhoneUtil = async () => {
  return new Promise((resolve) => {
    const Libphonenumber = require("google-libphonenumber");
    const phoneUtil = Libphonenumber.PhoneNumberUtil.getInstance();
    resolve(phoneUtil);
  });
};

export const getPhoneUtil = async () => {
  if (cachedPromise) {
    return cachedPromise;
  }

  cachedPromise = loadPhoneUtil();

  return cachedPromise;
};
