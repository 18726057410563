import clsx from "clsx";
import Typography from "../Typography";
import { getFilteredCountries, getFlagUrl } from "./countries";
import Dropdown from "../Dropdown";
import { forwardRef, useCallback, useMemo, useState } from "react";
import DownChevron from "../Icons/ArrowDown";
import styles from "./styles.module.scss";
import { useAtom } from "jotai";

const PhoneDropdown = forwardRef(function PhoneDropdown(
  { setErrored, country, setCountry },
  resizeRef
) {
  const [search, setSearch] = useState("");
  const [dropdown, setDropdown] = useState(false);

  const countries = useMemo(() => {
    return getFilteredCountries(search);
  }, [search]);

  const toggleDropdown = useCallback(() => {
    setDropdown((dropdown) => !dropdown);
  }, []);

  const onClick = useCallback(
    (country) => {
      setDropdown(false);
      setCountry(country);
      setSearch("");
      setErrored?.(false);
    },
    [setCountry, setErrored, setSearch, setDropdown]
  );

  const trigger = useMemo(() => {
    return (
      <div onClick={toggleDropdown}>
        <Typography
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              toggleDropdown();
            }
          }}
          className={clsx(styles.symbolPlaceholder)}
        >
          <span
            style={{
              lineHeight: 1,
              height: "15px",
            }}
          >
            <img
              src={getFlagUrl(country.countryCode)}
              className={styles.flag}
              alt={country.label}
            />
          </span>
          <span>{country.symbol}</span>
          <DownChevron
            size={8}
            className={clsx({ [styles.reversed]: dropdown })}
          />
        </Typography>
      </div>
    );
  }, [country, dropdown, toggleDropdown]);

  return (
    <Dropdown
      trigger={trigger}
      open={dropdown}
      setOpen={setDropdown}
      position="bottom"
      ref={resizeRef}
      theme={{
        dropdown: styles.dropdown,
        container: styles.container,
      }}
    >
      <input
        autoFocus
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        className={styles.search}
        placeholder="Search countries"
      />
      <div className={styles.countries} key={search}>
        {countries.map(({ countryCode, label, symbol }, i) => {
          return (
            <div
              className={styles.country}
              key={symbol + countryCode}
              onKeyDown={(e) => {
                e.stopPropagation();
                if (e.key === "Enter") {
                  onClick({ countryCode, label, symbol });
                }
              }}
              onClick={(e) => {
                e.stopPropagation();
                onClick({ countryCode, label, symbol });
              }}
            >
              <div className={styles.name}>
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img
                  className={styles.flag}
                  src={getFlagUrl(countryCode)}
                  alt={`${label} flag`}
                />
                <span className={styles.label}>{label}</span>
              </div>
              <span className={styles.symbol}>{symbol}</span>
            </div>
          );
        })}
      </div>
    </Dropdown>
  );
});

export default PhoneDropdown;
