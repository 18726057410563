import { Fragment } from 'react';

import { convertJsonStyleToString, TypographyVariants } from '../../Contracts';

import styles from './Typography.module.scss';

export const Typography = (props) => {

  let TypograpyVariant = props.variant;
  const { dangerouslySetInnerHTML = {} } = props;
  const { __html } = dangerouslySetInnerHTML;

  if (!TypograpyVariant) {
    TypograpyVariant = TypographyVariants.DEFAULT;
  }

  const getTypographyText = () => {
    return __html || props.text || props.children;
  };

  if (!props.text && !props.children && !__html && (!props.style && !props.desktopStyle && !props.tabletStyle && !props.mobileStyle)) {
    return null;
  }

  const removeDoubleQuotes = ({ input = '', search = '', replacement = '' }) => {
    return input.split(search).join(replacement);
  };

  const MEDIA_QUERY_BREAKPOINTS = {
    TABLET_MAX_WIDTH: removeDoubleQuotes({ input: styles['variables-tabletMediaQueryMaxWidth'], search: '"', replacement: '' }),
    TABLET_MIN_WIDTH: removeDoubleQuotes({ input: styles['variables-tabletMediaQueryMinWidth'], search: '"', replacement: '' }),
    MOBILE_MAX_WIDTH: removeDoubleQuotes({ input: styles['variables-mobileMediaQueryMaxWidth'], search: '"', replacement: '' })
  };

  return (
    <Fragment>
      {__html ? (
        <TypograpyVariant id={props.id} className={`${styles.Typography} ${props.className ? props.className : ''}`} dangerouslySetInnerHTML={{ __html }} />
      ) : (
        <TypograpyVariant id={props.id} className={`${styles.Typography} ${props.className ? props.className : ''}`}>
          {getTypographyText()}
        </TypograpyVariant>
      )}
      <style jsx={true} scoped={true}>
        {`
          .${styles.Typography} { ${convertJsonStyleToString(props.style || props.desktopStyle)} }
          @media screen and (max-width: ${MEDIA_QUERY_BREAKPOINTS.TABLET_MAX_WIDTH}) and (min-width: ${MEDIA_QUERY_BREAKPOINTS.TABLET_MIN_WIDTH}) { .${styles.Typography} { ${convertJsonStyleToString(props.tabletStyle)} } }
          @media screen and (max-width: ${MEDIA_QUERY_BREAKPOINTS.MOBILE_MAX_WIDTH}) { .${styles.Typography} { ${convertJsonStyleToString(props.mobileStyle)} } }
        `}
      </style>
    </Fragment>
  );

};

export default Typography;
