const DownChevron = ({ size = "18", color = "#000", className, style }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      color={color}
      viewBox="0 0 18 12"
      fill="none"
      style={style}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M2 2L9 9L16 2"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default DownChevron;
