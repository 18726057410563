import { useEffect, useRef } from "react";
import { Portal } from "../Portal";
import clsx from "clsx";
import styles from "./styles.module.scss";

export const Modal = ({
  children,
  className,
  open,
  onClose,
  innerDivCss,
  center,
  closable = true,
  showOverlay = false,
  innterDivClassName,
  isSidebar = false,
}) => {
  const modalRef = useRef(null);

  useEffect(() => {
    if (closable && open) {
      const onEscape = (event) => {
        if (event.key === "Escape") {
          onClose();
        }

        if (event.key === "Esc") {
          onClose();
        }
      };

      document.addEventListener("keydown", onEscape);
      return () => {
        document.removeEventListener("keydown", onEscape);
      };
    }
  }, [onClose, closable, open]);

  useEffect(() => {
    if (closable && open) {
      const onClick = (event) => {
        const modal = modalRef.current;
        const target = event.target;

        const isInModal = modal.contains(target);

        if (modal && !isInModal) {
          onClose();
        }
      };

      document.addEventListener("click", onClick, true);

      return () => {
        document.removeEventListener("click", onClick, true);
      };
    }
  }, [onClose, closable, open]);

  // disable html, body and #__next scroll when modal opens using class no-scroll
  useEffect(() => {
    if (open) {
      document.body.classList.add("no-scroll");
      document.getElementById("__next").classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
      document.getElementById("__next").classList.remove("no-scroll");
    }
  }, [open]);

  const renderOverlay = () => {
    if (!showOverlay) {
      return null;
    }

    return (
      <div
        aria-hidden="true"
        className={clsx(styles.ModalOverlay, open && styles.OverlayOpen)}
      />
    );
  };

  return (
    <Portal>
      {renderOverlay()}
      {(open || showOverlay) && (
        <div className={clsx(className, styles.ModalContainer, { open })}>
          <div
            ref={modalRef}
            className={clsx(
              styles.ModalInnerDiv,
              center && styles.Center,
              innterDivClassName,
              isSidebar && styles.Sidebar,
              isSidebar && open && styles.SidebarOpen,
              { open }
            )}
            style={{ ...innerDivCss }}
          >
            {children}
          </div>
        </div>
      )}
    </Portal>
  );
};
