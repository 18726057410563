import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import Typography from "../Common/Typography";
import { useAtom, useSetAtom } from "jotai";
import { leadFormAtom } from "./state";
import LabelledInput from "../Common/LabelledInput";
import Dropdown from "../Common/Dropdown";
import clsx from "clsx";
import DownChevron from "../Common/Icons/ArrowDown";
import PhoneInput from "../Common/PhoneInput";
import { getCountryFromSymbol } from "../Common/PhoneDropdown/countries";
import Button from "../Common/Button";
import { ButtonType } from "../Contracts";
import { getTruncatedPhoneNumber } from "../../utils/validate-phone";
import { getPhoneUtil } from "../../utils/get-phone-util";
import axios from "axios";
import ThankYouPage from "./thankyou";
import { bookDemoFormOpen, phoneUtilAtom } from "../../state";

const BookDemoFormMain = ({
  showNoOfProductsInput = true,
  showNoOfCustomersPerDayInput = true,
  addFormContainerPadding = true,
  showTitle = true,
  showNatureOfBusinessInput = true,
  buttonText,
}) => {
  const [state, dispatch] = useAtom(leadFormAtom);
  const [invalid, setInvalid] = useState(false);
  const [invalidPhone, setInvalidPhone] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [phoneUtil, setPhoneUtil] = useAtom(phoneUtilAtom);
  const [showThankYou, setShowThankYou] = useState(false);
  const setSidebar = useSetAtom(bookDemoFormOpen);
  const searchParams = useRef({});

  const handleSetPhoneUtil = useCallback(async () => {
    setPhoneUtil(await getPhoneUtil());
  }, [setPhoneUtil]);

  useEffect(() => {
    if (phoneUtil) return;
    handleSetPhoneUtil();
  }, [handleSetPhoneUtil, phoneUtil]);

  useEffect(() => {
    const sendUrlParams = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      searchParams.current = Object.fromEntries(urlParams.entries());
    };
    sendUrlParams();
  }, []);

  const setValue = useCallback(
    (type, value) => {
      if (type === "country") {
        setInvalid(false);
        dispatch({ type, error: "", value: value.symbol });
        return;
      }
      setInvalid(false);
      dispatch({ type, error: "", value });
    },
    [dispatch]
  );

  const isValidState = (state, inValidPhone) => {
    const errors = {};

    if (!state.businessName.value.length) {
      errors.businessName = "Enter name";
    }

    if (showNatureOfBusinessInput && !state.natureOfBusiness.value.length) {
      errors.natureOfBusiness = "Select nature of business";
    }

    if (showNoOfProductsInput && !state.numberOfProducts.value.length) {
      errors.numberOfProducts = "Enter the number of products";
    }

    if (showNoOfCustomersPerDayInput && !state.numberOfCustomersPerDayProductsSharedWith.value.length) {
      errors.numberOfCustomersPerDayProductsSharedWith =
        "Enter the number of customers";
    }

    if (!state.phone.value.length || inValidPhone) {
      errors.phone = "Enter correct phone";
    }

    if (!state.country.value.length) {
      errors.country = "enter_correct_country";
    }

    return errors;
  };

  const checkFormValidity = useCallback(() => {
    const errors = isValidState(state, invalidPhone);

    if (Object.keys(errors).length) {
      dispatch({ type: "errors", errors });
      return false;
    }

    return true;
  }, [state, invalidPhone, dispatch]);

  const toggleDropdown = useCallback((value) => {
    setDropdownOpen((dropdown) => {
      if (typeof value === "boolean") return value;
      return !dropdown;
    });
  }, []);

  const onSubmit = useCallback(async () => {
    console.log("hello")

    const isValid = checkFormValidity();
    setInvalid(!isValid);

    if (isValid) {
      try {
        const truncatedPhone = getTruncatedPhoneNumber({
          phone: state.phone.value,
          countryCode: getCountryFromSymbol(state.country.value).countryCode,
          phoneUtil,
        });

        if (truncatedPhone) {
          setValue("phone", truncatedPhone);
        }
        setSubmitting(true);

        const dataToSubmit = {
          businessName: state.businessName.value,
          natureOfBusiness: showNatureOfBusinessInput
            ? state.natureOfBusiness.value
            : "",
          numberOfProducts: showNoOfProductsInput
            ? state.numberOfProducts.value
            : "",
          numberOfCustomersPerDayProductsSharedWith:
            showNoOfCustomersPerDayInput
              ? state.numberOfCustomersPerDayProductsSharedWith.value
              : "",
          phoneNumber: `${state.country.value}${state.phone.value}`,
          queryParams: searchParams.current,
        };

        await axios.post(
          `https://api.quicksell.co/v1/internal/book-demo`,
          dataToSubmit,
          {
            headers: {
              Authorization: "ac1ae0d7-70a4-432f-adbd-7230dc75e661",
              "Content-Type": " application/json",
            },
          }
        );
        var pathname = window.location.pathname;
        let page = "";
        if (pathname === "/") {
          page = "home";
        } else if (pathname === "/b2b") {
          page = "b2b";
        } else if (pathname === "/b2c") {
          page = "b2c";
        } else if (pathname === "/quicksell-for-jewellery") {
          page = "jewellery";
        } else if (pathname === "/quicksell-for-gifting") {
          page = "gifting";
        } else if (pathname === "/quicksell-for-clothing") {
          page = "clothing";
        } else if (pathname === "/quicksell-for-toys") {
          page = "toys";
        } else if (pathname === "/native-app") {
          page = "native-app";
        } else if (pathname === "/get-native-app") {
          page = "get-native-app";
        } else if (pathname === "/analytics") {
          page = "analytics";
        } else if (pathname === "/private-showcasing") {
          page = "private-showcasing";
        } else if (pathname === "/cataloguing") {
          page = "cataloguing";
        } else if (pathname === '/b2b-ecommerce' ) {
          page = 'b2b-ecommerce';
        }
        window.sendEventToAnalytics("demo_booked", page);
        window.dataLayer = window.dataLayer || [];
        try {
          switch (page) {
            case "home": {
              window.dataLayer.push({ event: "main_page_form_submit" });
              if (typeof fbq === "function") {
                fbq("trackCustom", "main_page_form_submit");
              }
              break;
            }
            case "b2b": {
              window.dataLayer.push({ event: "b2b_page_form_submit" });
              if (typeof fbq === "function") {
                fbq("trackCustom", "b2b_page_form_submit");
              }
              break;
            }
            case "b2c": {
              window.dataLayer.push({ event: "b2c_page_form_submit" });
              if (typeof fbq === "function") {
                fbq("trackCustom", "b2c_page_form_submit");
              }
              break;
            }
            case "jewellery": {
              window.dataLayer.push({ event: "jewellery_page_form_submit" });
              if (typeof fbq === "function") {
                fbq("trackCustom", "jewellery_page_form_submit");
              }
              break;
            }
            case "gifting": {
              window.dataLayer.push({ event: "gifting_page_form_submit" });
              if (typeof fbq === "function") {
                fbq("trackCustom", "gifting_page_form_submit");
              }
              break;
            }
            case "clothing": {
              window.dataLayer.push({ event: "clothing_page_form_submit" });
              if (typeof fbq === "function") {
                fbq("trackCustom", "clothing_page_form_submit");
              }
              break;
            }
            case "toys": {
              window.dataLayer.push({ event: "toys_page_form_submit" });
              if (typeof fbq === "function") {
                fbq("trackCustom", "toys_page_form_submit");
              }
              break;
            }
            case "native-app": {
              window.dataLayer.push({ event: "native_app_page_form_submit" });
              if (typeof fbq === "function") {
                fbq("trackCustom", "native_app_page_form_submit");
              }
              break;
            }
            case "get-native-app": {
              window.dataLayer.push({ event: "get_native_app_form_submit" });
              if (typeof fbq === "function") {
                fbq("trackCustom", "get_native_app_form_submit");
              }
              break;
            }
            case "analytics": {
              window.dataLayer.push({ event: "analytics_page_form_submit" });
              if (typeof fbq === "function") {
                fbq("trackCustom", "analytics_page_form_submit");
              }
              break;
            }
            case "private-showcasing": {
              window.dataLayer.push({
                event: "private_showcasing_page_form_submit",
              });
              if (typeof fbq === "function") {
                fbq("trackCustom", "private_showcasing_page_form_submit");
              }
              break;
            }
            case "cataloguing": {
              window.dataLayer.push({ event: "cataloguing_page_form_submit" });
              if (typeof fbq === "function") {
                fbq("trackCustom", "cataloguing_page_form_submit");
              }
              break;
            }
            case "b2b-ecommerce": {
              window.dataLayer.push({ event : "b2b_ecommerce_page_form_submit" });
              if (typeof fbq === "function") {
                fbq("trackCustom", "b2blanding_page_form_submit");
              }
              break;
            }

            default: {
              break;
            }
          }
        } catch (error) {
          // Invalid URL or relative url, not sure what to do in these cases
        }
        setShowThankYou(true);
      } catch (e) {
        const message = e?.message;
        console.error(e);
      } finally {
        setSubmitting(false);
      }
    }
  }, [state, checkFormValidity, setValue]);

  if (showThankYou) {
    return (
      <ThankYouPage
        close={() => {
          setShowThankYou(false);
          setSidebar(false);
        }}
      />
    );
  }

  return (
    <div
      className={`${styles.MainFormContainer} ${
        addFormContainerPadding && styles.FormContainerPadding
      }`}
    >
      {showTitle && (
        <Typography className={styles.MainFormTitle}>
          Book a personalized demo
        </Typography>
      )}

      <div
        style={{
          flex: 1,
        }}
      >
        <LabelledInput
          error={state.businessName.error}
          value={state.businessName.value}
          label={"Business Name"}
          id="business-name"
          onChange={(e) => setValue("businessName", e.target.value)}
          placeholder={"Aliva Jewels Pvt. Ltd."}
        />
        {showNoOfProductsInput && (
          <LabelledInput
            error={state.numberOfProducts.error}
            value={state.numberOfProducts.value}
            label={"How many products do you have?"}
            id="number-of-products"
            onChange={(e) => setValue("numberOfProducts", e.target.value)}
            placeholder={"10"}
          />
        )}
        {showNoOfCustomersPerDayInput && (
          <LabelledInput
            error={state.numberOfCustomersPerDayProductsSharedWith.error}
            value={state.numberOfCustomersPerDayProductsSharedWith.value}
            label={
              "How many customers do you share your products with in a day?"
            }
            id="number-of-customers-per-day-products-shared-with"
            onChange={(e) =>
              setValue(
                "numberOfCustomersPerDayProductsSharedWith",
                e.target.value
              )
            }
            placeholder={"100"}
          />
        )}
        {showNatureOfBusinessInput && (
          <div className={styles.BusinessNatureContainer}>
            <label className={styles.BusinessNatureLabel}>
              Nature Of Business
            </label>
            <Dropdown
              trigger={
                <div
                  className={clsx(styles.BusinessNatureTrigger)}
                  onClick={toggleDropdown}
                >
                  <Typography className={styles.TriggerText}>
                    {state.natureOfBusiness.value
                      ? state.natureOfBusiness.value
                      : " Select an option"}
                  </Typography>
                  <DownChevron
                    className={clsx(
                      styles.TriggerArrow,
                      dropdownOpen && styles.TriggerArrowOpen
                    )}
                    size={12}
                  />
                </div>
              }
              theme={{
                dropdown: styles.BusinessNatureDropdown,
              }}
              open={dropdownOpen}
              setOpen={toggleDropdown}
            >
              {[
                {
                  id: 1,
                  value: "B2C (Selling products to consumers)",
                },
                {
                  id: 2,
                  value: "B2B (Selling products to other businesses)",
                },
                {
                  id: 3,
                  value: "Both",
                },
              ].map(({ id, value }) => (
                <div
                  className={clsx(
                    styles.Item,
                    state.natureOfBusiness.value === value && styles.ItemActive
                  )}
                  key={id}
                  onClick={() => {
                    setValue("natureOfBusiness", value);
                    toggleDropdown(false);
                  }}
                >
                  {value}
                </div>
              ))}
            </Dropdown>
            <p
              className={styles.Error}
              style={{
                visibility: state.phone.error ? "visible" : "hidden",
              }}
            >
              {state.natureOfBusiness.error}
            </p>
          </div>
        )}
        <div className={styles.PhoneInputContainer}>
          <p className={styles.PhoneInputTitle}>Phone Number</p>
          <PhoneInput
            autoFocus
            value={{
              country: state.country.value,
              number: state.phone.value,
            }}
            setState={setValue}
            setInvalid={setInvalidPhone}
          />
          <p
            className={styles.Error}
            style={{
              visibility: state.phone.error ? "visible" : "hidden",
            }}
          >
            {state.phone.error}
          </p>
        </div>
      </div>

      <div className={styles.SubmitButtonContainer}>
        <Button
          type={ButtonType.PRIMARY}
          className={styles.SubmitButton}
          disabled={submitting || invalid}
          onClick={onSubmit}
        >
          {submitting ? (
            <img src="/assets/loader.svg" style={{ height: "24px" }} />
          ) : buttonText && buttonText.length > 0 ? (
            buttonText
          ) : (
            "SUBMIT"
          )}
        </Button>
      </div>
    </div>
  );
};

export default BookDemoFormMain;
