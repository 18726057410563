import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import clsx from "clsx";
import styles from "./styles.module.scss";
import { createPopper, preventOverflow, flip } from "@popperjs/core";

const useIsomorphicLayoutEffect =
  typeof window !== "undefined" ? useLayoutEffect : useEffect;

function assertIsNode(e) {
  if (!e || !("nodeType" in e)) {
    throw new Error(`Node expected`);
  }
}

const getStyler = (classes, themes) => {
  return {
    get: (classname) => {
      const stlyeClassName = classes[classname];
      const themeClassName = themes && themes[classname];

      return clsx(stlyeClassName, themeClassName);
    },
  };
};

const Dropdown = forwardRef(
  ({ children, theme, trigger, open, setOpen, position = "top" }, sizeRef) => {
    const styler = getStyler(styles, theme);
    const [container, setContainer] = useState(null);
    const outerContainerRef = useRef(null);
    const dropdownRef = useRef(null);

    useImperativeHandle(
      sizeRef,
      () => {
        return {
          resize: (node) => {
            setContainer(node);
          },
        };
      },
      []
    );

    const setContainerNode = (node) => {
      if (!sizeRef) setContainer(node);
    };

    useEffect(() => {
      const outerContainer = outerContainerRef.current;
      if (outerContainer) {
        const onMouseDown = (e) => {
          assertIsNode(e.target);

          if (!outerContainer.contains(e.target)) {
            setOpen(false);
          }
        };

        const onEscape = (e) => {
          if (e.key === "Escape") {
            setOpen(false);
          }
        };

        document.addEventListener("mousedown", onMouseDown);
        document.addEventListener("keydown", onEscape);

        return () => {
          document.removeEventListener("mousedown", onMouseDown);
          document.removeEventListener("keydown", onEscape);
        };
      }
    }, [setOpen]);

    useIsomorphicLayoutEffect(() => {
      if (dropdownRef.current && container && open) {
        createPopper(container, dropdownRef.current, {
          strategy: "fixed",
          modifiers: [
            preventOverflow,
            flip,
            {
              name: "offset",
              options: {
                offset: [0, 8],
              },
            },
            {
              name: "sameWidth",
              enabled: true,
              fn: ({ state }) => {
                state.styles.popper.width = `${state.rects.reference.width}px`;
              },
              effect({ state }) {
                state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
              },
              phase: "beforeWrite",
              requires: ["computeStyles"],
            },
          ],
        });
      }
    }, [open, container]);

    return (
      <div ref={outerContainerRef} className={styler.get("container")}>
        <div className={styler.get("trigger")} ref={setContainerNode}>
          {trigger}
        </div>
        {open && (
          <div
            className={styler.get("dropdown")}
            tabIndex={0}
            ref={dropdownRef}
          >
            {children}
          </div>
        )}
      </div>
    );
  }
);

Dropdown.displayName = "Dropdown";

export default Dropdown;
